/* Contenedor principal */
.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #5B9B5B; /* Fondo verde */
}

/* Contenedor de contenido */
.content-wrapper {
  text-align: center;
  padding: 20px;
}

/* Título */
.home-title {
  font-size: 3rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1.5rem;
}

/* Subtítulo */
.home-subtitle {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 2rem;
}

/* Contenedor de botones */
.home-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

/* Estilo para los botones */
.home-btn {
  width: 250px;
  height: 60px;
  font-size: 1.2rem;
  border-radius: 8px;
  color: #fff;
}

/* Botón de tipo primario */
.home-btn.ant-btn-primary {
  background-color: #34b759; /* Verde oscuro */
  border-color: #34b759;
}

.home-btn.ant-btn-primary:hover {
  background-color: #2a9746;
  border-color: #2a9746;
}

/* Botón de tipo por defecto */
.home-btn.ant-btn-default {
  background-color: #ffffff;
  color: #34b759;
  border-color: #34b759;
}

.home-btn.ant-btn-default:hover {
  background-color: #e6f7f1;
  color: #34b759;
  border-color: #34b759;
}

/* Ajustes responsivos */
@media (max-width: 768px) {
  .home-title {
    font-size: 2.5rem;
  }

  .home-subtitle {
    font-size: 1.2rem;
  }

  .home-btn {
    width: 220px;
    height: 55px;
    font-size: 1.1rem;
  }
}
/* Estilos para el contenedor de la tabla */
.table-container {
  padding: 20px;
}

/* Estilos del título */
.table-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

/* Estilos del alert */
.alert {
  margin-bottom: 1rem;
}

/* Estilos de la tabla para pantallas grandes */
.table-desktop {
  display: block;
}

/* Estilos para la vista en formato de lista para pantallas pequeñas */
.table-mobile {
  display: none;
}

/* Estilos para las filas en formato de lista */
.table-row {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: #f9f9f9;
}

/* Estilos para las celdas en formato de lista */
.table-cell {
  margin-bottom: 8px;
}

/* Mostrar la vista en formato de lista en pantallas pequeñas */
@media (max-width: 768px) {
  .table-desktop {
    display: none;
  }

  .table-mobile {
    display: block;
  }
}
.id-header {
  border-bottom: 2px solid #5db075; /* Línea separadora para ID Cabeza */
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: bold;
}