/* customStyles.css */

/* Estilos generales para el contenedor de los componentes */
.container {
    max-width: 1200px; /* Máximo ancho del contenedor */
    margin: 0 auto; /* Centrar el contenedor */
    padding: 1rem; /* Espaciado interno */
  }
  
  /* Estilos específicos para el componente List */
  .list-container {
    background-color: #F6F6F6; /* Gris claro de fondo */
    border-radius: 0.375rem; /* Bordes redondeados */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra sutil */
    padding: 1.5rem; /* Espaciado interno */
  }
  
  .list-item {
    border-bottom: 1px solid #ddd; /* Línea divisoria entre elementos */
    padding: 0.5rem 0; /* Espaciado vertical */
  }
  
  /* Estilos específicos para el componente Home */
  .home-container {
    background-color: #ffffff; /* Fondo blanco */
    border-radius: 0.375rem; /* Bordes redondeados */
    padding: 2rem; /* Espaciado interno */
    text-align: center; /* Alinear texto al centro */
  }
  
  .home-title {
    font-size: 2rem; /* Tamaño de fuente del título */
    color: #333; /* Color del texto */
  }
  
  /* Estilos específicos para el componente Register */
  .register-container {
    background-color: #ffffff; /* Fondo blanco */
    border-radius: 0.375rem; /* Bordes redondeados */
    padding: 2rem; /* Espaciado interno */
    max-width: 600px; /* Ancho máximo */
    margin: 0 auto; /* Centrar el contenedor */
  }
  
  .register-form {
    display: flex;
    flex-direction: column; /* Columna de campos de formulario */
  }
  
  .register-input {
    margin-bottom: 1rem; /* Espaciado entre campos */
  }
  
  .register-button {
    background-color: #007bff; /* Botón azul */
    color: white; /* Color del texto del botón */
    border: none;
    padding: 0.75rem; /* Espaciado interno */
    border-radius: 0.375rem; /* Bordes redondeados */
    cursor: pointer; /* Cursor de mano */
    font-size: 1rem; /* Tamaño de fuente del botón */
  }
  
  .register-button:hover {
    background-color: #0056b3; /* Color de fondo en hover */
  }
  
  /* Responsividad */
  @media (max-width: 768px) {
    .container {
      padding: 0.5rem; /* Reducir espaciado en pantallas más pequeñas */
    }
  
    .register-container {
      padding: 1rem; /* Reducir espaciado en pantallas más pequeñas */
    }
  }
  